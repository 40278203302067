import { motion } from "framer-motion";

const PageTransition = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}  // Start with opacity 0 and slight vertical shift
      animate={{ opacity: 1, y: 0 }}  // Fade in and move to position
      exit={{ opacity: 0, y: -20 }}   // Fade out and move up slightly when navigating away
      transition={{ duration: 0.3, ease: "easeInOut" }} // Smooth transition
    >
      {children}
    </motion.div>
  );
};

export default PageTransition;
