import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FiX } from "react-icons/fi";
import Chip from "./Chip";

const VideoViewerCard = ({ videoUrl, title, tool, chip }) => {
  const [isViewerOpen, setViewerOpen] = useState(false);

  useEffect(() => {
    if (isViewerOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto"; // Reset on unmount
    };
  }, [isViewerOpen]);

  const handleCardClick = () => setViewerOpen(true);
  const handleCloseViewer = () => setViewerOpen(false);

  return (
    <div className="relative flex items-center justify-center">
      {/* Card */}
        <div className="relative w-full h-auto overflow-hidden">
          <div onClick={handleCardClick} className="cursor-pointer transition-transform,shadow duration-300 ease-in-out transform md:hover:scale-95 bg-white  rounded-2xl  border border-transparent md:hover:border-slate-300 md:hover:transition-transform  md:hover:duration-300 overflow-hidden">
            <video
              src={videoUrl}
              className="w-full h-auto object-cover rounded-lg"
              muted
              loop
              playsInline
              autoPlay
            />
          </div>
          <div className="mt-2">
          {chip && (
            <div className="mb-2">
              <Chip {...chip} />
            </div>
          )}
            <p className="text-left text-balance font-medium">{title}</p>
            {tool ? (
              typeof tool === "string" ? (
                <p className="text-left text-sm text-slate-600">Tool: {tool}</p>
              ) : (
                <p className="text-left text-sm">
                  <a
                    href={tool.link}
                    className="text-blue-600 underline hover:text-blue-800"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {tool.label}
                  </a>
                </p>
              )
            ) : null}
          </div>
        </div>

      {/* Video Viewer */}
      <AnimatePresence>
        {isViewerOpen && (
          <motion.div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 z-40 flex items-center justify-center"
            
            onClick={handleCloseViewer} // Close viewer when clicking on overlay
          >
            {/* Video Viewer Content */}
            <motion.video
              src={videoUrl}
              className="max-w-full max-h-full object-contain"
              autoPlay
              loop
              muted
              playsInline
              
              onClick={(e) => e.stopPropagation()} // Prevent overlay click from closing viewer
            />

            {/* Close Button */}
            <button
              onClick={handleCloseViewer}
              className="absolute top-4 right-4 p-2 bg-slate-950 text-white rounded-full hover:bg-slate-900"
              aria-label="Close Viewer"
            >
              <FiX size={24} />
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default VideoViewerCard;
