import React from "react";

const Chip = ({ text, bgColor = "bg-slate-900", textColor = "text-white", borderColor = "border-transparent" }) => {
  return (
    <span className={`px-3 py-1 text-xs font-satoshimedium uppercase rounded-full ${bgColor} ${textColor} ${borderColor} border`}>
      {text}
    </span>
  );
};

export default Chip;
