import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import ScrollToTop from "./components/scrollToTop";
import PageTransition from "./components/PageTransition";
import CaseStudyLogin from "./pages/CaseStudyLogin";
import CaseStudyDs from "./pages/CaseStudyDs";
import CaseStudyInstallments from "./pages/CaseStudyInstallments";
import CaseStudyCoupons from "./pages/CaseStudyCoupons";
import Home from "./pages/Home";

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            <PageTransition>
              <Home />
            </PageTransition>
          }
        />
        <Route
          path="/case-study/login"
          element={
            <PageTransition>
              <CaseStudyLogin />
            </PageTransition>
          }
        />
        <Route
          path="/case-study/installments"
          element={
            <PageTransition>
              <CaseStudyInstallments />
            </PageTransition>
          }
        />
        <Route
          path="/case-study/coupons"
          element={
            <PageTransition>
              <CaseStudyCoupons />
            </PageTransition>
          }
        />
        <Route
          path="/case-study/designsystem"
          element={
            <PageTransition>
              <CaseStudyDs />
            </PageTransition>
          }
        />
      </Routes>
    </AnimatePresence>
  );
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AnimatedRoutes />
    </Router>
  );
}

export default App;