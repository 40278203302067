import React from "react";
import { useNavigate } from "react-router-dom";
import imgCoupons from "../assets/images/csCoupons.png";
import imgInstallments from "../assets/images/imgInstallments.png";
import { IconArrowLeft, IconDownload } from "@tabler/icons-react";
import { Button } from "gooui-kit";
import CaseStudyCard from "../components/CaseStudyCard";
import imgCar from "../assets/images/imgcar.png";

const CaseStudyLogin = () => {
  const navigate = useNavigate();
  const couponsPDF = process.env.PUBLIC_URL + "/CouponsCaseStudy.pdf";

  return (
    <div className="bg-slate-50">
    <div className="max-w-2xl mx-6 sm:mx-auto ">
      <div className="py-4">
        <button
          onClick={() => navigate("/")}
          className="p-2 bg-white text-slate-950 rounded-full hover:bg-slate-200"
          aria-label="Close Viewer"
        >
          <IconArrowLeft size={24} />
        </button>
      </div>
      <h1 className="text-4xl text-slate-950 font-inter leading-tight">
        Driving 85% Adoption by Enabling Sellers to Create and Manage Coupon
        Campaigns
      </h1>
      <h2 className="text-lg mt-6 mb-2 text-slate-950 font-satoshibold leading-relaxed">
        🔍 What Was the Problem?
      </h2>
      <ul className="list-disc ml-8 mt-2 text-slate-800  leading-relaxed">
        <li className="py-1">
          <span className="font-satoshibold">Lack of Autonomy</span>: Sellers
          had to rely on an account manager to create coupon campaigns, making
          the process slow and inefficient.
        </li>
        <li className="py-1">
          <span className="font-satoshibold">Limited Insights</span>: No
          real-time data on campaign performance, making it hard to optimize
          offers.
        </li>
        <li className="py-1">
          <span className="font-satoshibold">Complex Workflow</span>: The manual
          process was difficult to scale and manage effectively.
        </li>
      </ul>
      <h2 className="text-lg mt-6 text-slate-950 font-satoshibold leading-relaxed">
        💡 How I solved it
      </h2>
      <img
        src={imgCoupons}
        alt="Coupons design"
        className="w-full my-4 rounded-lg"
      />
      <ul className="list-disc ml-8 text-slate-800 leading-relaxed">
        <li className="py-1">
          <span className="font-satoshibold">
          Self-Service Coupon Creation
          </span>
          : Designed an intuitive system that allows sellers to create and manage campaigns independently.
        </li>
        <li className="py-1">
          <span className="font-satoshibold">Real-Time Performance Metrics</span>:
          Provided insights into revenue, impressions, and conversion rates to track success.
        </li>
        <li className="py-1">
          <span className="font-satoshibold">Streamlined UX</span>:
          Simplified the process with clear steps, making campaign management effortless.
        </li>
      </ul>
      <h2 className="text-lg mt-6 text-slate-950 font-satoshibold leading-relaxed">
        📈 Impact and Results
      </h2>
      <p className="py-1 ml-1 mt-3">
        <span className="font-satoshibold text-slate-800 leading-relaxed">
          ✅ 85% Increase in Adoption
        </span>
        , empowering sellers with full control.
      </p>
      <p className="py-1 ml-1">
        <span className="font-satoshibold text-slate-800 leading-relaxed">
          ✅ New Users: 30% Growth in Total Coupon Sellers.
        </span>
        {" "}More sellers started using coupons for the first time.
      </p>
      <p className="py-1 ml-1">
        <span className="font-satoshibold text-slate-800 leading-relaxed">
          ✅ Improved Decision-Making{" "}
        </span>
        with real-time performance tracking.
      </p>

      <div className="flex flex-col sm:flex-row justify-between items-center gap-4 rounded-2xl bg-slate-100 my-8 py-6 px-8">
        <div className="flex flex-col gap-2">
          <h2 className="text-lg text-slate-950 font-satoshibold leading-relaxed">
            Read the Full Case Study
          </h2>
          <p className="font-satoshi text-base text-slate-800 leading-relaxed">
            Explore the full design journey, including insights and challenges
            encountered.
          </p>
        </div>
        <div className="block sm:hidden">
          <button
            className="flex items-center gap-2 px-4 py-3 bg-slate-950 hover:bg-slate-800 text-white rounded-full focus:outline-none"
            onClick={() => window.open(couponsPDF, "_blank")}
          >
            <span className="whitespace-nowrap">Download PDF</span>
            <IconDownload size={24} />
          </button>
        </div>
        <div className="hidden sm:block">
          <Button
            className="whitespace-nowrap"
            label="Download PDF"
            rightIcon={<IconDownload size={24} color="currentColor" />}
            onClick={() => window.open(couponsPDF, "_blank")}
          />
        </div>
      </div>
      <hr className="border-t border-slate-300" />
      <h2 className="text-lg mt-8 text-slate-950 font-satoshibold leading-relaxed">
        Read other Case Studies
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 gap-y-8 mt-4 pb-24">
        <CaseStudyCard
          imageUrl={imgCar}
          title="31% Login Success Boost in Vehicle App Redesign"
          readingTime="5"
          link="/case-study/login"
          chip={{
            text: "Case Study",
            bgColor: "bg-blue-100",
            textColor: "text-blue-600",
            borderColor: "border-blue-300"
          }}
        />
        <CaseStudyCard
          imageUrl={imgInstallments}
          title="114% Revenue Growth with Funnels Redesign"
          readingTime="7"
          link="/case-study/installments"
          chip={{
            text: "Case Study",
            bgColor: "bg-blue-100",
            textColor: "text-blue-600",
            borderColor: "border-blue-300"
          }}
        />
      </div>
    </div>
    </div>
  );
};

export default CaseStudyLogin;
