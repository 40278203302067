import React from "react";
import { useNavigate } from "react-router-dom";
import { IconArrowLeft, IconDownload } from "@tabler/icons-react";
import { Button } from "gooui-kit";
import CaseStudyCard from "../components/CaseStudyCard";
import imgCar from "../assets/images/imgcar.png";
import imgCoupons from "../assets/images/imgcoupons.png";
import imgarticleds from "../assets/images/articleds.png";

const CaseStudyDs = () => {
  const navigate = useNavigate();
  const installmentsPDF = process.env.PUBLIC_URL + "/DS_CaseStudy.pdf";

  return (
    <div className="bg-slate-50">
    <div className="max-w-2xl mx-6 sm:mx-auto ">
      <div className="py-4">
        <button
          onClick={() => navigate("/")}
          className="p-2 bg-white text-slate-950 rounded-full hover:bg-slate-200"
          aria-label="Close Viewer"
        >
          <IconArrowLeft size={24} />
        </button>
      </div>
      <h1 className="text-4xl text-slate-950 font-inter leading-tight">
        Building a Modular and Scalable Design System from Figma to Code
      </h1>
      <h2 className="text-lg mt-6 mb-2 text-slate-950 font-satoshibold leading-relaxed">
        🎯 What Was the Objective?
      </h2>
      <ul className="list-disc ml-8 mt-2 text-slate-800  leading-relaxed">
        <li className="py-1">
          <span className="font-satoshibold">
            Transition from Wireframes to UI
          </span>
          : At the final wireframe stage, the goal was to build the UI for the
          first version of the app.
        </li>
        <li className="py-1">
          <span className="font-satoshibold">Establish a Design System</span>:
          Develop a scalable and modular design system to ensure consistency and
          efficiency in creating the app's UI.
        </li>
        <li className="py-1">
          <span className="font-satoshibold">Bridge Design and Code</span>:
          Align Figma tokens, components, and templates with the implementation
          in code for seamless handoff.
        </li>
      </ul>
      <h2 className="text-lg mt-6 text-slate-950 font-satoshibold leading-relaxed">
      📐 Creating the Design System
      </h2>
      <img
        src={imgarticleds}
        alt="Creating the design system"
        className="max-w-2xl mx-auto my-4 rounded-xl"
      />
      <ul className="list-disc ml-8 text-slate-800 leading-relaxed">
        <li className="py-1">
          <span className="font-satoshibold">
          UX Audit
          </span>
          : Identified the components, styles, and templates required to translate wireframes into UI.
        </li>
        <li className="py-1">
          <span className="font-satoshibold">Tokenization</span>:
          Defined tokens for colors, typography, spacing, and icons to standardize the foundation.
        </li>
        <li className="py-1">
          <span className="font-satoshibold">Componentization</span>:
          Built a modular system with flexible components that could adapt to different needs.
        </li>
        <li className="py-1">
          <span className="font-satoshibold">Compositions and Templates</span>:
          Designed reusable complex components and screen structures to speed up the creation of complete layouts.
        </li>
        <li className="py-1">
          <span className="font-satoshibold">1:1 Design-to-Code Alignment</span>:
          Ensured that all Figma tokens and components directly corresponded to their counterparts in code.
        </li>
      </ul>
      <h2 className="text-lg mt-6 text-slate-950 font-satoshibold leading-relaxed">
        📈 Impact and Results
      </h2>
      <p className="py-1 ml-1 mt-3">
        <span className="font-satoshibold text-slate-800 leading-relaxed">
          ✅ Streamlined UI Creation
        </span>
        : The design system enabled efficient UI development.
      </p>
      <p className="py-1 ml-1">
        <span className="font-satoshibold text-slate-800 leading-relaxed">
          ✅ Seamless Handoff
        </span>
        : The 1:1 design-to-code alignment minimized inconsistencies and improved collaboration.
      </p>
      <p className="py-1 ml-1">
        <span className="font-satoshibold text-slate-800 leading-relaxed">
          ✅ Scalable Foundation
        </span>
        : The modular system supports future iterations and scalability as the product evolves.
      </p>

      <div className="flex flex-col sm:flex-row justify-between items-center gap-4 rounded-2xl bg-slate-100 my-8 py-6 px-8">
        <div className="flex flex-col gap-2">
          <h2 className="text-lg text-slate-950 font-satoshibold leading-relaxed">
            Read the Full Case Study
          </h2>
          <p className="font-satoshi text-base text-slate-800 leading-relaxed">
          Explore the full design journey, including insights into how the design system was built to deliver a scalable and consistent UI.
          </p>
        </div>
        <div className="block sm:hidden">
          <button
            className="flex items-center gap-2 px-4 py-3 bg-slate-950 hover:bg-slate-800 text-white rounded-full focus:outline-none"
            onClick={() => window.open(installmentsPDF, "_blank")}
          >
            <span className="whitespace-nowrap">Download PDF</span>
            <IconDownload size={24} />
          </button>
        </div>
        <div className="hidden sm:block">
          <Button
            className="whitespace-nowrap"
            label="Download PDF"
            rightIcon={<IconDownload size={24} color="currentColor" />}
            onClick={() => window.open(installmentsPDF, "_blank")}
          />
        </div>
      </div>
      <hr className="border-t border-slate-300" />
      <h2 className="text-lg mt-8 text-slate-950 font-satoshibold leading-relaxed">
        Read other Case Studies
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 gap-y-8 mt-4 pb-24">
        <CaseStudyCard
          imageUrl={imgCar}
          title="31% Login Success Boost in Vehicle App Redesign"
          readingTime="5"
          link="/case-study/login"
          chip={{
            text: "Case Study",
            bgColor: "bg-blue-100",
            textColor: "text-blue-600",
            borderColor: "border-blue-300",
          }}
        />
        <CaseStudyCard
          imageUrl={imgCoupons}
          title="85% Adoption Increase on Coupon Campaigns"
          readingTime="5"
          link="/case-study/coupons"
          chip={{
            text: "Case Study",
            bgColor: "bg-blue-100",
            textColor: "text-blue-600",
            borderColor: "border-blue-300",
          }}
        />
      </div>
    </div>
    </div>
  );
};

export default CaseStudyDs;
