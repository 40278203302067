import React from "react";
import { useNavigate } from "react-router-dom";
import Chip from "./Chip";

const CaseStudyCard = ({ imageUrl, title, readingTime, link, chip }) => {
  const navigate = useNavigate();

  return (
    <div className="relative flex items-center justify-center">
      <div className="relative w-full h-auto overflow-hidden">
        <div
          onClick={() => navigate(link)}
          className="cursor-pointer transition-transform,shadow duration-300 ease-in-out transform md:hover:scale-95 border border-transparent md:hover:border-slate-300 bg-white rounded-2xl  md:hover:transition-transform md:hover:duration-300 overflow-hidden"
        >
          <img
            src={imageUrl}
            alt="Case Study Preview"
            className="w-full h-auto object-cover rounded-2xl"
          />
        </div>
        <div className="mt-2">
          {chip && (
            <div className="mb-2">
              <Chip {...chip} />
            </div>
          )}
          <p className="text-left text-base text-slate-900 font-medium mt-2">
            {title}
          </p>
          <p className="text-left text-sm text-slate-600">
            Reading time: {readingTime} min
          </p>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyCard;
